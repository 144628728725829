import React, { Component } from "react";
import Parallax, { AppearLayer } from "react-parallax-scroll";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Avatar from '@mui/material/Avatar';
import Tilt from 'react-parallax-tilt';

import "./animated.css";
import "./styles.css";

const notify = () => toast.error('Coming soon!', {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const buyDisabled = () => toast.error('Voyage 1 is closed but we are planning the next voyage! Check our socials for more details...', {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.start = React.createRef();
    this.gate = React.createRef();
    this.state = {
       myVar: "teste",
    };
  }

  componentDidMount() {
    this.start.current.scrollIntoView();
  }

  render() {
    return (
      <div ref={this.start}>
        <div className="fixedBar">
          <a href="https://t.me/EliteVaultProtocol" target="_blank" rel="noopener noreferrer">
            <CIcon icon={icon.cibTelegram} size="sm"/>
          </a>
          <a href="https://discord.gg/CDkJdK8KHz" target="_blank" rel="noopener noreferrer">
            <CIcon icon={icon.cibDiscord} size="sm"/>
          </a>
          <a href="https://twitter.com/elite_vault" target="_blank" rel="noopener noreferrer">
            <CIcon icon={icon.cibTwitter} size="sm"/>
          </a>
          <a href="mailto:contact@elitevault.io" target="_blank" rel="noopener noreferrer">
            <CIcon icon={icon.cibMailRu} size="sm"/>
          </a>
        </div>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <Parallax>
          <div className="header-100 gate">
            <div className="logoWrapper">
              <AppearLayer in="slideInDown" duration="2s">
                <img src="/images/logo_new_letters.png" alt="logo" />
              </AppearLayer>
            </div>
            <div className="keyWrapper">
              <div className="key">
                <AppearLayer in="slideInUp" duration="2s">
                  <img src="/images/key_512_slim.gif" alt="logo" />
                </AppearLayer>
              </div>
            </div>
            <div style={{position: "absolute", top: "0vh", left: "0px"}}>
              <AppearLayer in="slideInLeft" duration="2s">
                <div className="band">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8} className="bandText">
                        <span className="bandText1">
                          Your first step towards
                        </span>
                        <br />
                        <span className="bandText2">
                          sustainability and transparency
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {/* <a href="https://buy.elitevault.io/" target="_blank" rel="noopener noreferrer">
                          <button className="myButton buyButton">
                            Buy Pod
                          </button>
                        </a> */}
                        <button className="myButton buyButton" onClick={buyDisabled}>
                          Buy Pod
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </AppearLayer>
            </div>
            <div className="leftButtons">
              <AppearLayer in="slideInLeft" duration="2s">
                <a href="https://elitevault.gitbook.io/whitepaper/" target="_blank" rel="noopener noreferrer">
                  <button className="myButton">
                    Whitepaper
                  </button>
                </a>
              </AppearLayer>
            </div>
            <div className="rightButtons">
              <AppearLayer in="slideInRight" duration="2s">
                <a href="https://vault.elitevault.io/" target="_blank" rel="noopener noreferrer">
                  <button className="myButton">
                  Vault
                  </button>
                </a>
              </AppearLayer>
            </div>
          </div>
          <div className="header-25"></div>
          <div className="header-100 width-50">
            <h1 className="glow">About EVP</h1>
            <Box sx={{ flexGrow: 1 }}>
              <AppearLayer in="pulse" duration="2s">
                <Grid container spacing={2} className="aboutGrid">
                  <Grid item xs={12} className="aboutGridItem">
                    <Box className="container">
                    Tired of Sunk costs? So are we. It’s time to swim with the Whales. Are you ready for the <span className="accented">Voyage</span>?
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="aboutGridItem">
                    <Box className="container">
                      Elite Vault Protocol's has a unique strategy to provide a sustainable project with <span className="accented">transparency</span>, stable <span className="accented">growth</span>, reasonable <span className="accented">passive income</span>, and <span className="accented">flexibility</span>.
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="aboutGridItem">
                    <Box className="container">
                    With the introduction of <span className="accented">Pod NFT's</span>, this is our way of showing NFT's can have a <span className="accented">true backed value</span>.
                    </Box>
                  </Grid>
                </Grid>
              </AppearLayer>
            </Box>
          </div>
          <div className="header-25"></div>
          <div className="header-100">
            <h1 className="glow">Roadmap</h1>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <h2 className="phaseTitle">Phase 1: "Startup Phase"</h2>
                <h3 className="phaseSubTitle">Initialing Initial Dive</h3>
                <Timeline position="alternate">
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Create social media accounts
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                      <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Whitepaper development
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Create NFT designs
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Complete Mint Contract
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Initiate Phase 1 Marketing
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Community Testnet
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Whitelist Verification
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        KYC
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Voyage 1 Launch
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Allocate Funds for Voyage 1
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Grid>
              <Grid item xs={12} md={6}>
                <h2 className="phaseTitle">Phase 2: "Buildout Phase"</h2>
                <h3 className="phaseSubTitle">Expanding the EVP Protocol's Framework</h3>
                <Timeline position="alternate">
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Add V1 to ToFuNFT
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilCheckAlt} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Add V1 to NFTrade
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Staking Audit
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Design Voyage V2 NFTs - V4 NFTs
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Deploy Website Version 2
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Deploy Marketplace
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Initiate Phase 2 Marketing
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Develop VaaS Partnership 1
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Mint VaaS Partnership 1
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Develop Beta Program for VaaS
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Mint for the Month of April/May
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Add to ToFu/NFTrade
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector className="bgWhite" />
                      <TimelineDot className="bgAccented">
                        <CIcon icon={icon.cilClock} size="sm" className="iconSize iconColor"/>
                      </TimelineDot>
                      <TimelineConnector className="bgWhite" />
                    </TimelineSeparator>
                    <TimelineContent className="timelineTextWrapper" sx={{ py: '12px', px: 2 }}>
                      <Typography className="timelineText">
                        Deploy
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Grid>
            </Grid>
          </div>
          <div className="header-25"></div>
          <div className="header-100 width-95">
            <h1 className="glow">Team</h1>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/james.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">James</span>
                  <span className="job">CEO</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/digitalist.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">Digitalist</span>
                  <span className="job">Lead Advisor</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/naturex.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">NatureX</span>
                  <span className="job">Marketing</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/stellar.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">Stellar</span>
                  <span className="job">Innovation</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/cryptogrypto.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">CryptoGrypto</span>
                  <span className="job">Lead Moderator</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/ed.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">_&gt;Ed</span>
                  <span className="job">Designer</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/goosen999.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">Goosen999</span>
                  <span className="job">Developer</span>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="">
                  <Tilt>
                    <Avatar className="avatarSize avatarWrapper" alt="avatar" src="/images/jared.png" />
                  </Tilt>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className="teamInfo">
                  <span className="name">Jared</span>
                  <span className="job">COO</span>
                  <a style={{marginTop: "20px"}} href="https://www.fuddoxx.com/elitheum-v1" target="_blank" rel="noopener noreferrer">
                    <img style={{width: "200px"}} src="/images/fuddoxx.png" alt="logo" />
                  </a>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className="header-25"></div>
          <div className="header-100 width-50">
            <h1 className="glow">FAQ</h1>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What is EVP?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Elite Vault Protocol is a unique strategy to provide a sustainable project with transparency, stable growth, reasonable passive income, and flexibility. Through its investment strategies it allows Pod holders access to a treasury vault that has secured stable assets and passive income.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What is a Pod?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  A Pod is a Non-fungible token. Enter the Pod. Unlike projects offering unrealistic commitments to deliver an outrageous APY relying on ponzi mechanics, the focus of the Pod is sustainability, realistic growth/income, and a new approach to flexibility.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What makes EVP unique from other protocols?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The Pod is an asset and backed by the value of the Vault. Each Pod must be deployed to the Vault to receive distributed profits and earn passive income. As the Vault Treasury grows, so does the value of your Pod. What makes this unique is that you now have a flexible asset that can be sold/traded or gifted. This solves the issue with the "Nodes as a Service" projects not being able to be transferred.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What is a Voyage?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  A Voyage is a limited batch of Pods. Once the treasury vault has reached a certain value, a new voyage of Pods will be released for people to deploy.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>How much will a Pod cost?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Voyage 1 Pods will be listed at $500 worth of BNB.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What are the maintenance fees for?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                The maintenance fees are to help keep the project sustainable and cover costs behind the scenes with developers, marketing, and designing the next Voyage. This will also fund the lottery and a Victim Relief fund for those who have fallen to scams. These fees will need to be paid every 30 days and can be paid up to 6 months in advance. Failure to pay will result in your Pod NFT unstaking from the Vault.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What is the potential APR/APY?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  This will be calculated at a later date as we determine the best route to invest the Vault Treasury funds.  We believe compounding is the best strategy to exponentially grow your investments and passive income.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>What happens when my NFT is unstaked?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Your NFT is still backed by the treasury but you will not receive the passive income rewards until you have paid the required fees to restake your NFT.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Parallax>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        <footer style={{color: "white", textAlign: "center", margin: "10px"}}>
        <Parallax>
          <div className="logoWrapper" style={{paddingTop: "60px",paddingBottom: "50px"}}>
            <AppearLayer in="slideInUp" duration="2s">
              <img src="/images/logo_new_letters.png" alt="logo" />
            </AppearLayer>
          </div>
          </Parallax>
          <small>&copy; Copyright 2022, Elite Vault Protocol</small>
        </footer>
      </div>
    );
  }
}

export default App;